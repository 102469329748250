import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { create } from 'apisauce';
import styles from './landing.styles.js';
import { Slide, Zoom } from 'react-reveal';
import { ThreeDots } from 'react-loader-spinner';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { Link } from 'gatsby';
import { Layout } from 'layouts';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import VerticalFixedNav from '../styles/verticalFixedNav';
import SDGMapChart from '../components/Charts/SDGMapChart';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import useLocalStorage from '../shared/useLocalStorage';
import MapChart from '../components/MapView/MapChart.jsx';
import Image from '../components/Image';
import getHeaderLInks from '../shared/headerLinks.js';
import getStaticImageURL from '../shared/getStaticImageURL.js';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });

const {
  Backbg,
  CountrySpecific,
  programsGrid,
  ParagraphContent,
  descriptionParagraph,
  Pt_20,
  ImageMain,
  GridCenter,
  PartnerLogo,
  logosGrid,
  pt_12,
  ImageStyle,
} = styles;

const Landing = ({ ...props }) => {
  const { t } = useTranslation();

  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const [sdgMapData, setSdgMapData] = useLocalStorage(
    `${data.programCode ? data.programCode : ''}sdgMapData`,
    null,
    1
  );

  const [projectsCount, setProjectsCount] = useLocalStorage(
    `${data.programCode ? data.programCode : ''}projectsCount`,
    null,
    1
  );

  const [countryProjectsData, setCountryProjectsData] = useLocalStorage(
    `${data.programCode ? data.programCode : ''}countryProjectsData`,
    null,
    1
  );

  const [sectorsCount, setSectorsCount] = useLocalStorage(
    `${data.programCode ? data.programCode : ''}sectorsCount`,
    null,
    1
  );

  const [countriesCount, setCountriesCount] = useLocalStorage(
    `${data.programCode ? data.programCode : ''}countriesCount`,
    null,
    1
  );

  const headerLinks = getHeaderLInks('', props.path, data.noLinks);

  const footerLinks = [
    {
      name: 'FAQ',
      link: props.path + '/faq',
    },
    {
      name: 'About',
      link: props.path + '/about',
    },
    {
      name: 'Contact Us',
      link: props.path + '/contactUs',
    },
    {
      name: 'Disclaimer',
      link: props.path + '/disclaimer',
    },
  ];

  const { programs, pageHeader, headerLogo, headerLogoScroll } = data;

  const logos = pageContext.logos || [];

  const frontmatter =
    ((data.indexData || {}).childMarkdownRemark || {}).frontmatter || {};

  const { landingData, description, partners } = frontmatter;

  const regionalPartners = (partners || []).filter(
    partner => partner.isRegional
  );
  regionalPartners.sort((a, b) => a.order - b.order);
  const ourPartners = (partners || []).filter(partner => !partner.isRegional);
  ourPartners.sort((a, b) => a.order - b.order);

  const { VerticalFixedNavStyles, smoothScrolling } = VerticalFixedNav;

  const [isLoading, setLoading] = useState(false);

  const programLink = pageContext.programUrl;

  useEffect(() => {
    setLoading(!sdgMapData ? true : false);
    const isIE = /*@cc_on!@*/ false || !!(document || {}).documentMode;
    if (!isIE) {
      smoothScrolling();
    }
    apiSauceInstance
      .get(Constants.getSdgAndCountryCount)
      .then(response => {
        setSdgMapData(response.data.sdgTile);
        setCountryProjectsData(response.data.countryProjectsCount);
        setProjectsCount(response.data.projectCount);
        setSectorsCount(response.data.sectorsCount);
        setCountriesCount(response.data.countriesCount);
        setLoading(false);
      })
      .catch(error => {
        if (error) {
          setLoading(false);
        }
      });
  }, []);

  const pageSections = [
    {
      href: '.',
      dataNumber: 1,
      label: 'Intro',
    },
    {
      href: '#info',
      dataNumber: 2,
      label: 'Info',
    },
    {
      href: '#pages',
      dataNumber: 3,
      label: 'Pages',
    },
    {
      href: '#programs',
      dataNumber: 4,
      label: 'Programs',
    },
    {
      href: '#partners',
      dataNumber: 5,
      label: 'Partners',
    },
  ];

  const getDesc = str => {
    if (str && str.length && str.length > 155) {
      return str.substr(0, 155) + '...';
    }
    return str;
  };

  return (
    <Layout
      programLink={programLink}
      isHome={true}
      headerLinks={headerLinks}
      logos={logos}
      homeSliderData={[landingData]}
      footerLinks={footerLinks}
      animation
      counts={{ projectsCount, sectorsCount: 6, countriesCount }}
      projectsCount={projectsCount}
      sectorsCount={sectorsCount}
      countriesCount={countriesCount}
      programCode={props.path}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Investment Promotion Portal')}</title>
        <link rel="canonical" href={Constants.appUrl} />
      </Helmet>
      <Container css={VerticalFixedNavStyles}>
        <nav id="cd-vertical-nav">
          <ul>
            {pageSections.map(
              section =>
                section && (
                  <li key={section.dataNumber}>
                    <a
                      href={section.href}
                      data-number={section.dataNumber}
                      className="cd-dot-link"
                    >
                      <span className="cd-dot"></span>
                      <span className="cd-label">{section.label}</span>
                    </a>
                  </li>
                )
            )}
          </ul>
        </nav>

        <section id="info" className="cd-section">
          <div style={Pt_20}>
            <Container css={ParagraphContent}>
              <GridContainer xs={12} sm={12}>
                <GridContainer xs={12} sm={12} lg={12}>
                  <Slide left>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <h4>{t('INVESTMENT PROMOTION PORTAL')}</h4>

                      {description ? (
                        description.map(descriptionKey => {
                          return (
                            <p css={descriptionParagraph} key={descriptionKey}>
                              {t(descriptionKey)}
                            </p>
                          );
                        })
                      ) : (
                        <>
                          <p css={descriptionParagraph}>
                            Investment has been recognized as a central element
                            in economic and social development for decades, as
                            increased national and foreign investments lead to
                            enhanced productivity and competitiveness.
                          </p>
                          <p css={descriptionParagraph}>
                            The United Nations Industrial Development
                            Organization (UNIDO) was the first international
                            organization to promote foreign investment to
                            developing countries and is the only one with
                            comprehensive expertise in industrial development,
                            including policy advice, impact assessment of new
                            technologies, technology transfer, quality and
                            environmental concerns, and private sector
                            development. Throughout its history, UNIDO has been
                            widely recognized for its ability to assist
                            developing countries in attracting foreign direct
                            investment - a key driver in the industrial process,
                            stimulating technological and institutional
                            upgrading and fostering knowledge transfer and job
                            creation. Foreign direct investment has the
                            potential to ignite and sustain the dynamics of
                            change that allow societies to combat poverty and
                            achieve economic prosperity.
                          </p>
                          <p css={descriptionParagraph}>
                            To help bridging the technology divide by assisting
                            developing countries in identifying their technology
                            needs, accessing sources of new advanced technology
                            and building-up institutional capacities and skills
                            for effective promotion of new technologies to the
                            market place
                          </p>
                        </>
                      )}
                    </GridItem>
                  </Slide>
                  <GridItem style={GridCenter} xs={12} sm={12} md={12} lg={10}>
                    <ImageMain styles="margin-bottom:50px;">
                      {!isLoading ? (
                        <SDGMapChart
                          sdgMapData={sdgMapData}
                          programUrl={programLink}
                        />
                      ) : (
                        <Container width="100%">
                          <div className="three-dots-loader">
                            <ThreeDots
                              color="#5990ff"
                              height={100}
                              width={100}
                              timeout={10000}
                            />
                          </div>
                        </Container>
                      )}
                    </ImageMain>
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </Container>
          </div>
        </section>

        <section id="pages" className="cd-section" style={pt_12}>
          <GridContainer className="ImageTiltContainer">
            <GridItem md={7} className="content">
              <span css={ParagraphContent}>
                <h4>Access investment projects at your finger tips</h4>
                <p css={descriptionParagraph}>
                  Click to explore and find out more about projects Filter
                  projects using search and filter criteria on left side. Upon
                  clicking the project navigates to project details page which
                  has more details about investment project including company(if
                  any) and get contact details by clicking view contact button
                  on contact section.
                </p>
              </span>
            </GridItem>
            <GridItem md={5} sm={12}>
              <div className="wrapper">
                <div className="container" id="c1">
                  <span className="image">
                    <Link to={props.path + '/projects'}>
                      <img
                        alt=""
                        // src={require('../assets/img/gif-projects-page.gif')}
                        src={getStaticImageURL('gif-projects-page.gif')}
                      />
                    </Link>
                  </span>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </section>

        <section id="programs" className="cd-section">
          <div>
            <Container css={ParagraphContent}>
              <h4>{t('Programs')}</h4>
              <p className="description">{t('programs_desc_1')}</p>
            </Container>

            <GridContainer xs={12} sm={12} lg={12}>
              <MapChart
                countryProjectsData={countryProjectsData}
                partnerCountries={[]}
              />
            </GridContainer>

            <Zoom>
              <GridContainer css={programsGrid}>
                {programs.map((node, index) => (
                  <CountrySpecific key={index} xs={12} sm={12} md={3}>
                    <Link to={node.url}>
                      <GridContainer
                        style={{ height: '200px' }}
                        className="caption"
                        data-title={t(node.title)}
                        data-description={getDesc(
                          t((node.description || [])[0] || '') || ''
                        )}
                      >
                        <Img fluid={node.image.childImageSharp.fluid} />
                        <h4>{t(node.title)}</h4>
                        <Backbg />
                      </GridContainer>
                    </Link>
                  </CountrySpecific>
                ))}
              </GridContainer>
            </Zoom>
          </div>
        </section>

        <section id="partners" className="cd-section">
          {(ourPartners || []).length ? (
            <>
              <Container css={ParagraphContent}>
                <h4>
                  {t('Our Partners - Investment Promotion Agencies (IPAs)')}
                </h4>
              </Container>

              <GridContainer css={logosGrid}>
                {(ourPartners || []).map((partner, index) => {
                  return (
                    <GridItem xs={6} sm={4} md={2} key={index}>
                      <Link to={props.path + partner.url}>
                        <PartnerLogo css={ImageStyle}>
                          <Image imageName={partner.logo} />
                        </PartnerLogo>
                      </Link>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </>
          ) : (
            ''
          )}

          {(regionalPartners || []).length ? (
            <>
              <Container css={ParagraphContent}>
                <h4>{t('Our Regional Partners')}</h4>
              </Container>

              <GridContainer css={logosGrid}>
                {(regionalPartners || []).map((partner, index) => {
                  return (
                    <GridItem xs={6} sm={4} md={2} key={index}>
                      <Link to={props.path + partner.url}>
                        <PartnerLogo css={ImageStyle}>
                          <Image imageName={partner.logo} />
                        </PartnerLogo>
                      </Link>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </>
          ) : (
            ''
          )}
        </section>
      </Container>
    </Layout>
  );
};

export default Landing;

Landing.propTypes = {
  pageContext: PropTypes.object,
  path: PropTypes.any,
};
