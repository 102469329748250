import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
  ZoomableGroup,
} from 'react-simple-maps';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { geoCentroid } from 'd3-geo';
import { scaleQuantile } from 'd3-scale';
import { navigate } from 'gatsby';

const geographiesData = require('../../../static/geography.json');

const useStyles = makeStyles(theme => ({
  mapContainer: {
    cursor: 'grab',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '10px',
    // boxShadow: '0 0px 18px -12px rgba(0,0,0,0.56), 0 4px 25px 0 rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)',
    width: '100%',
    overflow: 'hidden',
    height: '650px',
  },
  mapChart: {
    height: '720px',
    width: '100%',
  },
  popover: {
    pointerEvents: 'none',
    opacity: 0.8,
  },
  paper: {
    padding: theme.spacing(1),
    background: '#FFF',
    color: '#3C4858',
    fontSize: '16px',
    fontWeight: '600',
  },
  tooltip: {
    fontSize: '14px',
    textAlign: 'center',
    whiteSpace: 'new-line',
  },
}));

const COLOR_RANGE = ['#97BC62FF', '#98bb65', '#85a951', '#70943b', '#2C5F2D'];

const CustomTooltip = ({ name, content }) => {
  if (content) {
    content.programName = [...new Set(content.programName || [])];
    return (
      <div
        className="custom-tooltip"
        style={{ padding: '0px', textAlign: 'left' }}
      >
        <p
          className="label"
          style={{
            padding: '0px',
            margin: '0',
            textAlign: 'left',
            color: '#3C4858',
          }}
        >
          {name}
        </p>
        {(content.programName || []).map((program, key) => {
          return (
            <p
              key={program + key}
              className="intro"
              style={{
                padding: '0px',
                margin: '0',
                textAlign: 'left',
                color: '#3C4858',
                fontWeight: '500',
              }}
            >
              {program}
            </p>
          );
        })}
        {content.projectsCount > 0 ? (
          <p
            className="intro"
            style={{
              padding: '0px',
              margin: '0',
              textAlign: 'left',
              fontWeight: '500',
              color: '#3C4858',
            }}
          >{`Total Projects: ${content.projectsCount}`}</p>
        ) : (
          ''
        )}
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  content: PropTypes.any,
  name: PropTypes.string,
};

const MapChart = ({ countryProjectsData, programCode, partnerCountries }) => {
  const projectsDefault =
    programCode === 'ACP'
      ? [
          {
            countryName: 'Ethiopia',
            programName: 'ACP Program',
            countryCode: 'ETH',
            countryID: 91,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Domanican Republic',
            programName: 'ACP Program',
            countryCode: 'DOM',
            countryID: 0,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Kenya',
            programName: 'ACP Program',
            countryCode: 'KEN',
            countryID: 169,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Senegal',
            programName: 'ACP Program',
            countryCode: 'SEN',
            countryID: 182,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Ghana',
            programName: 'ACP Program',
            countryCode: 'GHA',
            countryID: 55,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Papua New Guinea',
            programName: 'ACP Program',
            countryCode: 'PNG',
            countryID: 75,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Zambia',
            programName: 'ACP Program',
            countryCode: 'ZMB',
            countryID: 82,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Cameroon',
            programName: 'ACP Program',
            countryCode: 'CMR',
            countryID: 151,
            programCode: 'ACP',
            projectsCount: 0,
          },
        ]
      : programCode === 'CCP'
      ? [
          {
            countryName: 'Colombia',
            programName: 'Colombia Program',
            countryCode: 'COL',
            countryID: 0,
            programCode: 'CCP',
            projectsCount: 0,
          },
        ]
      : [
          {
            countryName: 'Ethiopia',
            programName: 'ACP Program',
            countryCode: 'ETH',
            countryID: 91,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Domanican Republic',
            programName: 'ACP Program',
            countryCode: 'DOM',
            countryID: 0,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Kenya',
            programName: 'ACP Program',
            countryCode: 'KEN',
            countryID: 169,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Senegal',
            programName: 'ACP Program',
            countryCode: 'SEN',
            countryID: 182,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Ghana',
            programName: 'ACP Program',
            countryCode: 'GHA',
            countryID: 55,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Papua New Guinea',
            programName: 'ACP Program',
            countryCode: 'PNG',
            countryID: 75,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Zambia',
            programName: 'ACP Program',
            countryCode: 'ZMB',
            countryID: 82,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Cameroon',
            programName: 'ACP Program',
            countryCode: 'CMR',
            countryID: 151,
            programCode: 'ACP',
            projectsCount: 0,
          },
          {
            countryName: 'Colombia',
            programName: 'Colombia Program',
            countryCode: 'COL',
            countryID: 0,
            programCode: 'CCP',
            projectsCount: 0,
          },
        ];

  const getMergedPartners = partnerCountries => {
    let output = [];
    if (partnerCountries) {
      output = partnerCountries.reduce((data, currentItem) => {
        // Get the index of the key-value pair.
        const index = data.reduce((n, item, i) => {
          return item.countryName === currentItem.countryName ? i : n;
        }, -1);
        // If the name is found,
        if (index >= 0) {
          // append the current value to its list of values.
          data[index].programName = data[index].programName.concat(
            currentItem.programName
          );
          // Otherwise,
        } else {
          // add the current item to o (but make sure the value is an array).
          const obj = {
            countryName: currentItem.countryName,
            programName: [currentItem.programName],
            countryCode: currentItem.countryCode || '',
            countryID: currentItem.countryID || 0,
            programCode: currentItem.programCode || '',
            projectsCount: currentItem.projectsCount || 0,
            color: currentItem.countryID ? '#2C5F2D' : '#97BC62FF',
          };
          data = data.concat([obj]);
        }

        return data;
      }, []);
    }
    return output;
  };

  const classes = useStyles();

  const [content, setContent] = useState('');
  const [anchorEl, setAnchorEl] = useState('');

  let projectsData = countryProjectsData || projectsDefault;

  projectsData = getMergedPartners([
    ...(projectsData || []),
    ...(partnerCountries || []),
  ]);

  const colorScale = scaleQuantile()
    .domain(projectsData.map(data => data.projectsCount))
    .range(COLOR_RANGE);

  const getDx = key => {
    const properties = {
      Ghana: 1,
      'Papua New Guinea': 30,
      Zambia: 40,
      Ethiopia: 30,
      Colombia: 30,
      Cameroon: 1,
      Kenya: 30,
      Senegal: 1,
      'Dominican Republic': 30,
    };
    return properties[key] || 0;
  };

  const getDy = key => {
    const properties = {
      Ghana: -40,
      'Papua New Guinea': -19,
      Zambia: -10,
      Ethiopia: -5,
      Colombia: -19,
      Cameroon: -25,
      Kenya: -5,
      Senegal: -45,
      'Dominican Republic': -19,
    };
    return properties[key] || 0;
  };

  return (
    <span className={classes.mapContainer}>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        id={content || ''}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl || null}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <span className={classes.tooltip}>
          {content.data && (
            <CustomTooltip name={content.name} content={content.data} />
          )}
        </span>
      </Popover>
      <ComposableMap
        projection="geoMercator"
        className={classes.mapChart}
        data-tip=""
        projectionConfig={{ scale: 150, center: [0, 10] }}
      >
        <ZoomableGroup zoom={1} disableZooming={true}>
          <Geographies geography={geographiesData}>
            {({ geographies }) => (
              <>
                {(geographies || []).map(geo => {
                  const { NAME_LONG, ISO_A3 } = geo.properties;
                  const current = projectsData.find(project =>
                    project.countryCode
                      ? project.countryCode === ISO_A3
                      : project.countryName === NAME_LONG
                  );
                  return (
                    <Geography
                      onClick={() =>
                        current &&
                        current.countryID &&
                        current.projectsCount > 0 &&
                        navigate(
                          current.countryID && current.projectsCount > 0
                            ? `all-programs/projects?Country=${current.countryID}`
                            : 'all-programs/projects'
                        )
                      }
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={e => {
                        setContent(
                          current ? { name: NAME_LONG, data: current } : ''
                        );
                        setAnchorEl(current ? e.currentTarget : null);
                      }}
                      onMouseLeave={() => {
                        setContent('');
                        setAnchorEl(null);
                      }}
                      style={{
                        default: {
                          //fill: current ? (current || {}).programCode === 'ACP' ? '#DF6589FF' : '#9B4A97FF' : '#D6D6DA',
                          fill: current
                            ? current.color || colorScale(current.projectsCount)
                            : '#D6D6DA',
                          stroke: '#FFF',
                          strokeOpacity: '0.2',
                          outline: '#000',
                          content: 'normal',
                        },
                        hover: current
                          ? {
                              fill: current
                                ? colorScale(current.projectsCount)
                                : '#D6D6DA',
                              cursor: 'pointer',
                            }
                          : {
                              fill: '#D6D6DA',
                              outline: 'none',
                            },
                        pressed: {
                          fill: '#D6D6DA',
                          outline: 'none',
                        },
                      }}
                    ></Geography>
                  );
                })}
                {geographies.map(geo => {
                  const { NAME_LONG, ISO_A3 } = geo.properties;
                  const current = projectsData.find(
                    project => project.countryCode === ISO_A3
                  );
                  const centroid = geoCentroid(geo);
                  return (
                    false && (
                      <g key={geo.rsmKey + '-name'}>
                        <Annotation
                          subject={centroid}
                          dy={getDy(NAME_LONG)}
                          dx={getDx(NAME_LONG)}
                          connectorProps={
                            <svg>
                              <path d="M10 10" />
                            </svg>
                          }
                        >
                          <text
                            x={0}
                            y={0}
                            fontSize={9}
                            dy={1}
                            dx={1}
                            fill="#3C4858"
                            style={{ cursor: 'pointer', color: '#3C4858' }}
                            alignmentBaseline="central"
                            onClick={() =>
                              current &&
                              current.countryID &&
                              current.projectsCount > 0 &&
                              navigate(
                                current.countryID && current.projectsCount > 0
                                  ? `all-programs/projects?Country=${current.countryID}`
                                  : 'all-programs/projects'
                              )
                            }
                          >
                            <tspan x="0" dy="0">
                              {NAME_LONG}
                            </tspan>
                            <tspan x="5" dy="10">
                              {current.projectsCount === 1
                                ? `${current.projectsCount} Project`
                                : current.projectsCount
                                ? `${current.projectsCount} Projects`
                                : ''}
                            </tspan>
                          </text>
                        </Annotation>
                      </g>
                    )
                  );
                })}
              </>
            )}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </span>
  );
};

MapChart.propTypes = {
  setTooltipContent: PropTypes.func,
  countryProjectsData: PropTypes.array,
  partnerCountries: PropTypes.array,
  programCode: PropTypes.string,
};

export default memo(MapChart);
