import { css } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../config/theme';

const Backbg = styled.div`
  position: absolute;
  background: #000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
`;
const CountrySpecific = styled.div`
  display: inline-block;
  width: 25%;
  margin: 20px;
  overflow: hidden;
  font-family: ${theme.fontFamily.heading};
  border-radius: 9px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  position: relative;
  text-align: center;
  transition: all 0.5s;
  max-height: 180px;
  vertical-align: top;
  @media (max-width: 1024px) {
    width: 38%;
  }
  @media (max-width: 768px) {
    width: 43%;
  }
  @media (max-width: 600px) {
    width: 90%;
    display: block;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 200px;
    margin: 0px auto;
    transition: transform 5s, filter 2s ease-in-out;
  }

  .caption::before {
    font-family: ${theme.fontFamily.body};
  }

  h4 {
    padding: 10px;
    color: #fff;
    position: absolute;
    z-index: 99999;
    top: 40%;
    text-align: center;
    margin: 0;
    width: 100%;
    font-family: ${theme.fontFamily.heading};
    font-weight: ${theme.fontWeight.heading};
    font-size: 22px;
  }
  &:hover {
    .gatsby-image-wrapper {
      -ms-transform: scale(2); /* IE 9 */
      -webkit-transform: scale(2); /* Safari 3-8 */
      transform: scale(2);
    }
    h4 {
      display: none;
    }
    background: transperant;
  }
`;
const Outerdiv = styled.div`
  height: 200px;
`;
const FullHeightImage = css`
  .gatsby-image-wrapper {
    height: 100vh;
    background-size: cover;
    background-position: center center;
    width: 100%;
    position: relative;
  }
`;
const FullLandingPage = css`
  position: relative;
`;
const MiddleSection = css`
  position: absolute;
  z-index: 999999;
  top: 30%;
  text-align: right;
  width: 100%;
  right: 20px;
  left: auto;
  @media (max-width: 600px) {
    right: 0px;
  }
`;
const Back_bg = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #00000094;
  z-index: 0;
`;

const ParagraphContent = css`
  p.description{
    color: ${theme.fontColor.body};
    font-size:calc(0.7em + 1vmin);
    line-height: 1.55em;
    text-align: justify;
    padding:0px;
    font-weight: ${theme.fontFamily.body};
    font-family: ${theme.fontFamily.body};
  }
  h2 {
    color: #3C4858;
    margin:40px 0px;
    margin-bottom: 15px;
    font-size:1.72rem;
    line-height: 1.55rem;
    text-align:center;
    font-weight: ${theme.fontFamily.heading};
    font-family: ${theme.fontFamily.heading};
    span
    {
      color: #00acc1;
    }
  }
  h4 {
    color: #3C4858;
    margin:40px 0px;
    font-size:1.72rem;
    line-height: 1.55rem;
    text-align:center;
    font-weight: ${theme.fontFamily.heading};
    font-family: ${theme.fontFamily.heading};
  }
  @media (max-width: 1024px) {

    p {
      padding:0px;
    }
    h4 {
      line-height: 40px;
    }
  }
  @media (max-width: 420px) {
    padding-left:0px;
    padding-right:0px;
    p {
      padding:0px;
    }
    h4 {
      font-size:1.12rem !important;
      line-height:25px;
    }
  }

}
`;
const ImageMain = styled.div`
  width: 95%;
  margin: 0px auto;
  text-align: center;
  margin-bottom: 30px;
  img {
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
      0 8px 10px -5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  @media (max-width: 420px) {
    width: 100%;
  }
`;

const descriptionParagraph = css`
    color: ${theme.fontColor.body};
    font-size: calc(0.7em + 1vmin);
    line-height: 1.55em;
    text-align: justify;
    padding: 0px;
    font-weight: ${theme.fontFamily.body};
    font-family: ${theme.fontFamily.body};
}`;

const Pt_20 = {
  paddingTop: '20px',
};

const programsGrid = css`
  display: flex;
  justify-content: space-evenly;
`;

const GridCenter = {
  marginLeft: 'auto',
  marginRight: 'auto',
};

const PartnerLogo = styled.div`
  .gatsby-image-wrapper {
    height: auto;
    width: 100%;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 15px -8px,
    // rgba(0, 0, 0, 0.2) 0px 8px 10px -5px;
  }
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 20px;
  @media (max-width: 1024px) {
    display: block;
    margin-bottom: 80px;
    margin-left: 0px;
  }
  @media (max-width: 480px) {
    display: block;
    margin-bottom: 40px;
    margin-left: 0px;
    padding: 20px;
  }
`;

const logosGrid = css`
  display: flex;
  align-items: center;
`;

const pt_12 = {
  paddingTop: '12vh',
};

const ImageStyle = {
  '&:hover': {
    transform: 'scale(1.2)',
    transitionDuration: '0.2s',
  },
  transitionDuration: '0.2s',
};

export default {
  Backbg,
  FullLandingPage,
  CountrySpecific,
  Outerdiv,
  FullHeightImage,
  MiddleSection,
  Back_bg,
  programsGrid,
  ParagraphContent,
  ImageMain,
  descriptionParagraph,
  Pt_20,
  GridCenter,
  PartnerLogo,
  logosGrid,
  pt_12,
  ImageStyle,
};
